import * as React from "react"
import { Link } from "gatsby"
import { cls } from "../../utils/helpers"

import Header from "../../components/layout/header"
import Footer from "../../components/layout/footer"

const Post1Page = () => {
  return (
    <div className="min-h-screen bg-primary-900 text-white">
      <div className="container mx-auto px-2">
        <Header />
        
        <section className="py-10">
          <div className="relative py-16 overflow-hidden">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
                <svg
                  className="absolute top-12 left-full transform translate-x-32"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-primary-600" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                </svg>

                <svg
                  className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-primary-600" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                </svg>
              </div>
            </div>

            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="text-lg max-w-prose mx-auto">
                <h1>
                  <span className="block text-lg text-center text-primary-200 font-semibold">News</span>
                  <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight sm:text-4xl">
                    The beginnings of the Boundary Blog
                  </span>
                </h1>
                {/* <p className="mt-8 text-xl leading-8">
                  Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem. At arcu, sit dui mi, nibh dui, diam eget
                  aliquam. Quisque id at vitae feugiat egestas ac. Diam nulla orci at in viverra scelerisque eget. Eleifend
                  egestas fringilla sapien.
                </p> */}
              </div>
              
              <div className="mt-6 prose prose-invert prose-primary-500 prose-lg mx-auto">
                <p>
                  Welcome to the Boundary Blog. Here you’ll find news, guides, changelogs and more. As Boundary is the ultimate CMS for developers, we will be posting lots of interesting developer guides here! For now, here's a few FAQ’s to answer some questions you may have.
                </p>
                
                <h3>Frequently asked questions</h3>

                <h5>What is Boundary CMS?</h5>

                <p>Boundary CMS is a developer friendly headless content management system that uses Configuration as Code to improve flexibility and ease of use for both developers and content writers.</p>

                <h5>What is a Headless CMS?</h5>

                <p>Headless CMS is a content management system that does not rely on a browser to render the content. Instead, content is served via an API, which gives more flexibility as to how it is used.</p>

                <h5>What is Configuration as Code?</h5>

                <p>Configuration as code is a software development practice where the configuration of a piece of software is stored in source code. One benefit this brings is that your CMS interface will always match the website source code.</p>

                <h5>What other features does Boundary have planned?</h5>

                <p>Currently we are building all the most important features you would expect a CMS to have. But in the future, we want to keep building on the developer experience and blur the line between an open-source, self hosted CMS and a hosted SaaS CMS. This would be the incorporation of serverless functions, themes, developer tools and more.</p>

                <h5>How can I join Boundary on this journey?</h5>

                <p>You can register for our beta launch list here!</p>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  )
}

export const Head = () => <>
  <title>Blog - Boundary CMS</title>

  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
  <link rel="manifest" href="/site.webmanifest" />
  <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#515BE7" />
  <meta name="msapplication-TileColor" content="#515BE7" />
  <meta name="theme-color" content="#ffffff" />
</>

export default Post1Page
